import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Grid, CircularProgress, Typography } from "@mui/material";
import ErrorHandling from "./ErrorHandling";
import constants from "../../utils/constants";
import { largeNumberLabel } from "../../utils/common";
import { currency, integrationType } from "../../utils/enums";
const CURRENCY_FIELD = gql `
  query CurrencyFieldQuery($type: String!) {
    integration(type: $type) {
      active
    }
  }
`;
const CURRENCY_CONVERSION = gql `
  mutation CurrencyFieldMutation($baseCurrency: String!, $poolCurrency: String!, $value: Float!) {
    convertCurrency(baseCurrency: $baseCurrency, poolCurrency: $poolCurrency, value: $value) {
      value
    }
  }
`;
const CurrencyField = ({ poolCurrency = currency.usd, baseCurrency = currency.usd, value = null }) => {
    const [getIntegration, { data: integrationData }] = useLazyQuery(CURRENCY_FIELD);
    const [convertValue, { loading, error, data }] = useMutation(CURRENCY_CONVERSION);
    React.useEffect(() => {
        getIntegration({
            variables: {
                type: integrationType.fixer_io,
            },
            onCompleted: (data) => {
                if (poolCurrency !== baseCurrency && data?.integration?.active) {
                    convertValue({
                        variables: {
                            poolCurrency,
                            baseCurrency,
                            value: parseFloat(value),
                        },
                    });
                }
            },
        });
    }, []);
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(CircularProgress, { size: 10, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const poolSymbol = constants.CURRENCY_SYMBOLS?.[poolCurrency] || constants.CURRENCY_SYMBOLS.usd;
    const baseSymbol = constants.CURRENCY_SYMBOLS?.[baseCurrency] || constants.CURRENCY_SYMBOLS.usd;
    const formatCurrency = (symbol, amount) => `\u202A${symbol}${largeNumberLabel(amount)}`;
    if (poolCurrency !== baseCurrency && integrationData?.integration?.active) {
        return (_jsxs(Grid, { container: true, direction: "row", spacing: 1, children: [_jsx(Typography, { children: formatCurrency(poolSymbol, value) }), _jsx(Typography, { fontWeight: "bold", children: " \u2248 " }), _jsxs(Typography, { children: [" ", formatCurrency(baseSymbol, data?.convertCurrency?.value)] })] }));
    }
    else {
        return _jsx(Typography, { children: formatCurrency(poolSymbol, value) });
    }
};
export default CurrencyField;
