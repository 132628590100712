import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Grid, CircularProgress, FormField, FormDialog, ErrorHandling } from "../../mui";
import { ContentFields, ContentActions } from "../content";
import PoolSections from "../../Pools/PoolSections";
import { camelCaseToWords } from "../../../utils/common";
import constants from "../../../utils/constants";
import { FormFieldType, FeatureType, UserPermission, currency } from "../../../utils/enums";
const SEARCH_SCHEMAS = gql `
  query SearchSchemas($organizationModelId: ID!) {
    organizationModel(id: $organizationModelId) {
      id
      schemas {
        id
        key
        type
        options
        relation
        modelName
      }
    }
  }
`;
const ManagePoolContentDialog = ({ open = false, displayPool = false, onDialogChange = () => null, onSave = () => null, onDelete = () => null, defaultSchema = null, poolData = null, poolId = null, operationType = constants.OPERATION_TYPE.ADD, notIncludedFields = [], fields = [], permissions = [], organizationModelId = null, children, }) => {
    const baseCurrency = localStorage.getItem("currency") || currency.usd;
    const [pool, setPool] = React.useState(defaultSchema);
    const [getModelSchema, { loading, error: schemaError, data }] = useLazyQuery(SEARCH_SCHEMAS);
    React.useEffect(() => {
        setPool(poolData || defaultSchema);
    }, [poolData]);
    React.useEffect(() => {
        if (open) {
            getModelSchema({
                variables: {
                    organizationModelId,
                },
            });
        }
    }, [open]);
    React.useEffect(() => {
        if (operationType === constants.OPERATION_TYPE.ADD) {
            setPool(defaultSchema);
        }
        if (operationType === constants.OPERATION_TYPE.EDIT && poolData) {
            setPool(poolData);
        }
    }, [operationType]);
    const onPoolChange = (event) => {
        if (event?.target?.type === FormFieldType.Checkbox.toLowerCase()) {
            setPool({
                ...pool,
                [event.target.name]: event.target.checked,
            });
        }
        else {
            setPool({
                ...pool,
                [event.target.name]: event.target.value,
            });
        }
    };
    const onContentChange = (event, schema, contents = []) => {
        let tempContents = contents;
        let value = event.target.value;
        if (event?.target?.type === FormFieldType.Checkbox.toLowerCase()) {
            value = event.target.checked.toString();
        }
        const currentContent = pool?.contents?.find((c) => c.key === schema.key) || null;
        if (tempContents.length === 0) {
            tempContents = [
                {
                    key: schema?.key,
                    schemaId: schema?.id,
                    value,
                },
            ];
        }
        else {
            if (currentContent) {
                tempContents = contents?.map((content) => content?.schemaId === schema?.id
                    ? {
                        ...content,
                        key: schema?.key,
                        value,
                    }
                    : content);
            }
            else {
                tempContents = [
                    ...tempContents,
                    {
                        key: schema?.key,
                        schemaId: schema?.id,
                        value,
                    },
                ];
            }
        }
        const unique = tempContents.filter((content, index) => {
            return index === tempContents.findIndex((c) => content?.schemaId === c?.schemaId);
        });
        setPool({
            ...pool,
            contents: unique,
        });
    };
    if (loading)
        return (_jsx(FormDialog, { open: true, children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { marginTop: "20%" }, children: _jsx(CircularProgress, { size: 60, color: "primary" }) }) }) }));
    if (schemaError)
        return _jsx(ErrorHandling, { error: schemaError });
    const manageActionPermitted = permissions?.find((permission) => permission?.feature === FeatureType.ContentsManage)?.permission ===
        UserPermission.Manage;
    return (_jsxs(FormDialog, { open: open, setOpen: () => onDialogChange({
            open: !open,
            id: null,
            action: null,
        }), title: operationType, actions: _jsx(ContentActions, { onClose: () => onDialogChange({
                open: !open,
                id: null,
                action: null,
            }), onSave: manageActionPermitted ? () => onSave(pool) : null, onDelete: manageActionPermitted ? onDelete : null }), children: [_jsxs(Grid, { container: true, spacing: 2, children: [displayPool && (_jsx(ContentFields, { disabled: !manageActionPermitted, content: pool || null, edtContent: onPoolChange, notIncludedFields: notIncludedFields, fields: fields, baseCurrency: baseCurrency, poolCurrency: poolData?.currency })), data?.organizationModel?.schemas?.map((schema) => (_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { disabled: !manageActionPermitted, name: schema?.key, label: camelCaseToWords(schema?.key), fieldType: schema?.type || FormFieldType.TextField, enumValues: schema?.options || [], value: pool?.contents?.find((c) => c?.schemaId === schema?.id)?.value, onChange: (e) => onContentChange(e, schema, pool?.contents), size: "small", relation: schema?.relation || false, modelName: schema?.modelName || "", baseCurrency: baseCurrency, poolCurrency: poolData?.currency }) }, schema.id))), pool?.readOnly?.map((content, index) => (_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { disabled: true, name: content?.label, label: camelCaseToWords(content?.label), value: content?.value, size: "small" }) }, index)))] }), children && (_jsx(Grid, { item: true, xs: 12, sx: { my: 4 }, children: children })), operationType === constants.OPERATION_TYPE.EDIT && (_jsx(Grid, { item: true, xs: 12, children: _jsx(PoolSections, { disabled: !manageActionPermitted, poolId: poolId || null }) }))] }));
};
export default ManagePoolContentDialog;
