import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { makeStyles, Grid, Typography, Card, CardActionArea, CardMedia, Divider, useTheme, ErrorHandling, Tooltip, Loader, Pill, FormSnackBar, } from "../components/mui";
import { ContentTypography } from "../components/common/content";
import { ManageIntegrationDialog } from "../components/common/dialogs";
import AppLayout from "./common/AppLayout";
import { FormBar } from "../components/common/forms";
import { FeatureType, UserPermission } from "../utils/enums";
import constants from "../utils/constants";
import ColorModeContext from "../routes/ColorModeContext";
const INTEGRATIONS = gql `
  query IntegrationsView {
    integrations {
      id
      name
      key
      secret
      active
      importBridge
      exportBridge
      dynamicBridge
    }
    currentUser {
      id
      role
      organization {
        id
        primaryTheme
      }
      platformSettings {
        mode
      }
      permissions {
        id
        feature
        permission
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
    container: {
        backgroundColor: "#FFFFFF",
        borderRadius: "24px !important",
        padding: 24,
        [theme.breakpoints.down("md")]: {
            padding: 16,
        },
    },
    containerArea: {
        borderRadius: "24px !important",
    },
}));
const IntegrationsContainer = (props) => {
    const userId = localStorage.getItem("userId") || null;
    const organizationId = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    const [search, setSearch] = React.useState("");
    const filters = ["All", "Active", "New"];
    const [currentFilter, setCurrentFilter] = React.useState("All");
    const [integrations, setIntegrations] = React.useState(constants.DEFAULT_INTEGRATIONS);
    const [openIntegrationDialog, setIntegrationDialog] = React.useState({
        open: false,
        integration: null,
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    React.useEffect(() => {
        if (!userId || !organizationId) {
            return history.push(RouteConstants.Login.path);
        }
    }, []);
    const { loading, error, data, refetch } = useQuery(INTEGRATIONS);
    React.useEffect(() => {
        if (data) {
            colorMode.defaultMode({
                mode: data?.currentUser?.platformSettings?.mode,
            });
            colorMode.togglePrimaryTheme({
                color: data?.currentUser?.organization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
        }
    }, [data]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onSearch = (value) => {
        if (!value || value === "")
            return setIntegrations(constants.DEFAULT_INTEGRATIONS);
        let list = constants.DEFAULT_INTEGRATIONS?.map((category) => {
            const filteredList = category?.list?.filter((item) => item?.name?.toLowerCase()?.includes(value?.toLowerCase()));
            if (filteredList?.length > 0) {
                return {
                    name: category.name,
                    list: filteredList,
                };
            }
            return null;
        })?.filter((category) => category !== null);
        setIntegrations(list);
    };
    const onFilter = (value) => {
        if (!value || value === "")
            return setIntegrations(constants.DEFAULT_INTEGRATIONS);
        let list = [];
        if (value === "All") {
            list = constants.DEFAULT_INTEGRATIONS;
        }
        if (value === "New") {
            list = constants.DEFAULT_INTEGRATIONS?.map((category) => {
                const newItems = category?.list?.filter((integration) => integration?.new === true);
                return {
                    name: category?.name,
                    list: newItems,
                };
            })?.filter((category) => category?.list?.length > 0);
        }
        if (value === "Active") {
            list = constants.DEFAULT_INTEGRATIONS?.map((category) => {
                const newItems = category?.list?.filter((integration) => {
                    const organizationIngetration = data?.integrations?.filter((i) => i?.name === integration?.name)?.[0] || null;
                    if (organizationIngetration?.active) {
                        return true;
                    }
                    else {
                        return false;
                    }
                });
                return {
                    name: category?.name,
                    list: newItems,
                };
            })?.filter((category) => category?.list?.length > 0);
        }
        setCurrentFilter(value);
        setIntegrations(list);
    };
    const manageActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.Integrations)
        ?.permission === UserPermission.Manage;
    return (_jsxs(AppLayout, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), openIntegrationDialog?.open && (_jsx(ManageIntegrationDialog, { open: openIntegrationDialog?.open, integration: openIntegrationDialog?.integration, setSnackBar: setSnackBar, onDialogChange: () => setIntegrationDialog({
                    open: !openIntegrationDialog,
                    integration: null,
                }) })), _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", justifyContent: "center", className: classes.section, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { align: "right", variant: "h5", color: "primary", m: 1, fontWeight: "bold", field: "integrationsLabel" }) }), _jsx(Grid, { item: true, xs: 12, sm: 12, children: _jsx(Divider, { flexItem: true }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormBar, { showDelete: null, setActionDialogState: null, openFiltersDialog: false, setFiltersDialog: null, openSchemaDialog: false, setSchemaDialog: null, deleteFunctionality: false, view: null, setView: null, search: search, onSearch: onSearch, refetch: () => window.location.reload() }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, spacing: 2, direction: "row", children: filters?.map((filter, index) => (_jsx(Grid, { item: true, children: _jsx(Pill, { label: filter, onClick: () => onFilter(filter), isSelected: currentFilter === filter }) }, index))) }) }), _jsx(Grid, { item: true, xs: 12, children: integrations?.map((category, index) => (_jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { align: "left", variant: "h6", color: "secondary", m: 2, fontWeight: "bold", children: category?.name }) }), category?.list?.map((integration, listIndex) => {
                                    const organizationIngetration = data?.integrations?.filter((i) => i?.name === integration?.name)?.[0] || null;
                                    let border = null;
                                    let borderStyle = null;
                                    let borderColor = null;
                                    let backgroundColor = "#A5A8AD";
                                    if (organizationIngetration?.active) {
                                        border = "3px";
                                        borderStyle = "solid";
                                        borderColor = theme.palette.primary[500];
                                        backgroundColor = theme.palette.primary[500];
                                    }
                                    return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: integration.title, placement: "right-end", children: _jsx(CardActionArea, { className: classes.containerArea, children: _jsx(Card, { className: classes.container, sx: {
                                                        border,
                                                        borderStyle,
                                                        borderColor,
                                                        "&:hover": {
                                                            boxShadow: `0px 24px 24px 4px ${backgroundColor}`,
                                                            cursor: "pointer",
                                                            position: "relative",
                                                        },
                                                    }, onClick: () => manageActionPermitted
                                                        ? setIntegrationDialog({
                                                            open: true,
                                                            integration: {
                                                                organizationId,
                                                                id: organizationIngetration?.id || undefined,
                                                                active: organizationIngetration?.active || false,
                                                                key: organizationIngetration?.key || "",
                                                                secret: organizationIngetration?.secret || "N/A",
                                                                name: integration?.name || "",
                                                                category: integration?.category || "",
                                                                type: integration?.type || "",
                                                                description: integration?.description || "",
                                                                title: integration?.title || "",
                                                                logo: integration?.logo || "",
                                                            },
                                                        })
                                                        : null, children: _jsx(CardMedia, { alt: "integration", image: integration.logo, title: integration.name, sx: {
                                                            objectFit: "contain",
                                                            height: 124,
                                                            maxWidth: "100%",
                                                        }, loading: "lazy", component: "img" }) }) }) }) }, listIndex));
                                })] }, index))) })] })] }));
};
export default IntegrationsContainer;
