import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Paper, Checkbox, TableSortLabel, TablePagination, IconButton, } from "@mui/material";
import { FormFieldType, FormFieldOrder, currency } from "../../utils/enums";
import { Edit, Check, OpenInNew } from "@mui/icons-material";
import { getContent } from "../common/content";
import FormField from "./FormField";
import constants from "../../utils/constants";
function EnhancedTableHead(props) {
    const { order, orderBy, numSelected, rowCount, onSelectAllClick, onRequestSort, headers, handleSelectAllVisible, handleRowVisible, navigateRowVisible, } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (_jsx(TableHead, { children: _jsxs(TableRow, { children: [handleSelectAllVisible && (_jsx(TableCell, { padding: "checkbox", width: "3%", children: _jsx(Checkbox, { color: "primary", indeterminate: numSelected > 0 && numSelected < rowCount, checked: rowCount > 0 && numSelected === rowCount, onChange: onSelectAllClick }) })), headers.map((headCell, index) => (_jsx(TableCell, { align: headCell.align, sortDirection: orderBy === headCell.key ? order : false, width: headCell?.width || "40%", sx: { mx: 0, px: 0 }, children: _jsx(Tooltip, { title: getContent("sortMessage"), children: _jsx(TableSortLabel, { active: orderBy === headCell.key, direction: orderBy === headCell.key ? order : constants.DEFAULT_ORDER, onClick: createSortHandler(headCell.key), children: headCell.label }) }) }, index))), handleRowVisible && _jsx(TableCell, { width: "3%" }), navigateRowVisible && _jsx(TableCell, { width: "3%" }), _jsx(TableCell, { width: "3%" })] }) }));
}
const FormTable = ({ headers = [], rows = [], fields = [], notIncludedFields = [], editRow = null, handleSelectAllClick = () => null, handleSelectAllVisible = false, handleRowAction = () => null, handleRowVisible = false, navigateRowVisible = false, navigateRowAction = () => null, onSubmitCheck = () => null, refetch = () => null, defaultOrder = FormFieldOrder.asc, order = FormFieldOrder.asc, orderBy = constants.DEFAULT_SORT, count = 0, page = 0, rowsPerPage = 0, rowsPerPageOptions = constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), pagination = true, }) => {
    const baseCurrency = localStorage.getItem("currency") || currency.usd;
    const theme = useTheme();
    const handleRequestSort = (event, property) => {
        let newOrder = defaultOrder;
        if (order === defaultOrder) {
            newOrder = FormFieldOrder.desc;
        }
        refetch({
            filters: { newPage: 0, rowsPerPage, order: newOrder, orderBy: property },
        });
    };
    return (_jsxs(Paper, { sx: {
            p: 1,
            width: 1,
            marginTop: theme.spacing.unit * 3,
            overflowX: "auto",
        }, children: [_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(EnhancedTableHead, { order: order, orderBy: orderBy, onRequestSort: handleRequestSort, rowCount: rows.length, headers: headers, numSelected: rows.filter((i) => i.selected)?.length, onSelectAllClick: handleSelectAllClick, handleSelectAllVisible: handleSelectAllVisible, handleRowVisible: handleRowVisible, navigateRowVisible: navigateRowVisible }), _jsx(TableBody, { children: rows?.map((row, index) => (_jsxs(TableRow, { hover: true, sx: { "&:last-child td, &:last-child th": { border: 0 } }, selected: row.selected, "aria-checked": row.selected, tabIndex: -1, children: [handleSelectAllVisible && (_jsx(TableCell, { padding: "checkbox", children: _jsx(Checkbox, { color: "primary", name: "selected", checked: row.selected, onChange: (e) => editRow(e, row) }) })), headers?.map((header, index) => (_jsx(TableCell, { component: header?.component, scope: header?.scope, align: header?.align, sx: { mx: 0, px: 0 }, children: _jsx(FormField, { disabled: notIncludedFields.includes(header.key) || editRow === null || !row?.editable, name: header.key, fieldType: fields?.find((f) => f.name === header.key)?.type?.name || FormFieldType.TextField, enumValues: fields?.find((f) => f.name === header.key)?.type?.enumValues?.map((e) => e.name) || [], value: row[header.key], onChange: (e) => editRow(e, { ...row, edited: true }), size: "small", baseCurrency: baseCurrency, poolCurrency: row?.currency }) }, index))), handleRowVisible && (_jsx(TableCell, { children: _jsx(Tooltip, { title: getContent("manageContentMessage"), children: _jsx(IconButton, { size: "small", onClick: () => handleRowAction({
                                                    open: true,
                                                    id: row.id || null,
                                                    action: constants.OPERATION_TYPE.EDIT,
                                                }), children: _jsx(Edit, { fontSize: "inherit" }) }) }) })), navigateRowVisible && (_jsx(TableCell, { children: _jsx(Tooltip, { title: getContent("openContentMessage"), children: _jsx(IconButton, { onClick: () => navigateRowAction(row.id), size: "small", children: _jsx(OpenInNew, { fontSize: "inherit", color: "primary" }) }) }) })), _jsx(TableCell, { children: row.edited && (_jsx(Tooltip, { title: getContent("checkContentMessage"), children: _jsx(IconButton, { onClick: () => onSubmitCheck(row), size: "small", children: _jsx(Check, { fontSize: "inherit", color: "primary" }) }) })) })] }, index))) })] }) }), pagination ? (_jsx(TablePagination, { component: "div", rowsPerPageOptions: rowsPerPageOptions || constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), count: count || 0, rowsPerPage: rowsPerPage || parseInt(constants.PAGE_LIMIT), page: page || 0, onPageChange: (event, newPage) => {
                    refetch({
                        filters: { newPage, rowsPerPage },
                    });
                }, onRowsPerPageChange: (event) => {
                    refetch({
                        filters: { newPage: 0, rowsPerPage: parseInt(event.target.value, 10) },
                    });
                } })) : null] }));
};
export default FormTable;
