import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import pluralize from "pluralize";
import { Grid, Button, FormField, DeleteHandling } from "../../mui";
import ContentTypography from "./ContentTypography";
import { FormFieldType } from "../../../utils/enums";
import { camelCaseToWords } from "../../../utils/common";
import constants from "../../../utils/constants";
export const ContentActions = ({ onSave = null, onClose = null, onDelete = null }) => (_jsxs(Grid, { container: true, spacing: 1, justifyContent: "flex-end", alignItems: "flex-end", children: [onSave && (_jsx(Grid, { item: true, children: _jsx(Button, { onClick: onSave, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "saveLabel" }) }) })), onClose && (_jsx(Grid, { item: true, children: _jsx(Button, { onClick: onClose, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "closeLabel" }) }) })), onDelete && (_jsx(Grid, { item: true, children: _jsx(DeleteHandling, { onDelete: onDelete, variant: "text" }) }))] }));
const ContentFields = ({ disabled = false, content = null, edtContent, notIncludedFields = [], fields = [], baseCurrency = null, poolCurrency = null, }) => (_jsx(_Fragment, { children: Object?.keys?.(content)?.map((key, index) => {
        if (notIncludedFields?.includes(key)) {
            return null;
        }
        const field = fields?.find((f) => f?.name === key);
        if (!field) {
            return null;
        }
        let fieldType = field?.type?.name || FormFieldType.TextField;
        let enumValues = field?.type?.enumValues?.map((e) => e?.name) || [];
        let relation = field && Object?.keys(constants.GQL_FIELD_TYPE)?.includes(field?.type?.kind);
        if (relation) {
            fieldType =
                field?.type?.kind === constants.GQL_FIELD_TYPE.LIST ? FormFieldType.MultiSelect : FormFieldType.SingleSelect;
        }
        return (_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { disabled: disabled, name: key, label: camelCaseToWords?.(key) || "", fieldType: fieldType, enumValues: enumValues, value: content[key], onChange: (e) => edtContent(e, content), size: "small", modelName: pluralize?.(field?.name) || "", relation: relation, baseCurrency: baseCurrency, poolCurrency: poolCurrency }) }, index));
    }) }));
export default ContentFields;
